.react-datepicker__today-button {
  color: #216ba5;
  background-color: white;
  border-top: 0px;
  margin: 0 10px 5px auto;
  width: 60px;
  padding: 5px;
  border-radius: 3px;
  transform: translateY(-5px);

  &:hover {
    background-color: #f0f0f0;
  }
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  color: #95989b;
  font-size: x-large;
  padding-bottom: 0.5rem;
}

$percent: (
  0: 0%,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
);

@each $key, $value in $percent {
  .top-#{$key} {
    top: $value;
  }
  .right-#{$key} {
    right: $value;
  }
  .bottom-#{$key} {
    bottom: $value;
  }
  .left-#{$key} {
    left: $value;
  }
}

.absolute-center {
  @extend .position-absolute;
  @extend .top-50;
  @extend .left-50;
  transform: translate(-50%, -50%);
}

.position-absolute-0 {
  @extend .position-absolute;
  @extend .top-0;
  @extend .right-0;
  @extend .bottom-0;
  @extend .left-0;
}

#wrapper {
  position: relative;
}

#sidebar-wrapper {
  overflow-y: scroll;
  min-width: 15rem;
  height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-in-out;
  -moz-transition: margin 0.25s ease-in-out;
  -o-transition: margin 0.25s ease-in-out;
  transition: margin 0.25s ease-in-out;
}

#sidebar-wrapper .sidebar-heading {
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#wrapper.toggled #page-content-wrapper {
  min-width: auto;
  width: 100%;
}

// #sidebar-wrapper .list-group:last-of-type {
//   flex-grow: 1;
//   justify-content: flex-end;
// }

@media (max-width: 768px) {
  #sidebar-wrapper {
    z-index: 999;
    width: 100%;
    max-width: 100%;
    position: absolute;
    transform: translateX(-100%);
    -webkit-transition: transform 0.25s ease-in-out;
    -moz-transition: transform 0.25s ease-in-out;
    -o-transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
  }

  #wrapper.toggled #sidebar-wrapper {
    transform: translateX(0);
  }
}

.user-icon {
  font-size: 0.5em;
  float: left;
  border-radius: 4em;
  border: 0.1rem solid#007bff;
  height: 2.3rem;
  width: 2.3rem;
  background: none;
  padding: 0.1rem;
}

.user-icon::before {
  content: " ";
  display: block;
  height: 0.5rem;
  width: 0.5rem;
  background: #007bff;
  position: relative;
  left: 0.7rem;
  top: -0.1rem;
  border-radius: 2em;
}
.user-icon::after {
  content: " ";
  display: block;
  height: 0.65rem;
  width: 1.3rem;
  background: #007bff;
  position: relative;
  left: 0.3rem;
  top: 0;
  border-radius: 2rem 2rem 0 0;
}

.user-info {
  z-index: 100;
  position: absolute;
  right: 0;
  top: 3.4rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  list-style: none;
  background-color: white;
  border-end-start-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.6rem rgba(0, 0, 0, 0.15);

  & li {
    padding: 0.4rem;
  }
}
